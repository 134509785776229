import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly8C2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });

  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/8C/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/8C/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/8C/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/8C/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/8C/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/class-assembly/8C/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/class-assembly/8C/7.webp`;
  const p8 = `${config.base_image_url}/home/events-activities/class-assembly/8C/8.webp`;
  const p9 = `${config.base_image_url}/home/events-activities/class-assembly/8C/9.webp`;
  const p10 = `${config.base_image_url}/home/events-activities/class-assembly/8C/10.webp`;
  const p11 = `${config.base_image_url}/home/events-activities/class-assembly/8C/11.webp`;
  const p12 = `${config.base_image_url}/home/events-activities/class-assembly/8C/12.webp`;
  

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      {
          src: p9,
          source: p9,
          rows: 1,
          cols: 1,
        },
        {
          src: p10,
          source: p10,
          rows: 1,
          cols: 1,
        },
        {
          src: p11,
          source: p11,
          rows: 1,
          cols: 1,
        },
        {
          src: p12,
          source: p12,
          rows: 1,
          cols: 1,
        },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Krishna Janmashtami 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 8C  Date: 26 August 2024
        </Typography>
        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Set thy heart upon thy work, but never on its reward."- Ved Vyasa, Bhagwad Geeta

<br/>
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 8C presented an impressive assembly on ‘ Krishna Janamashtami’ to mark this
        Indian festival which is celebrated every year. It mainly focussed on the life events of Lord Krishna and its
        practical implications in modern times   
        <br></br>
        The assembly commenced with the thought for the day followed by a brief introduction of the topic .
        The following day, the students presented a beautiful dance drama depicting the different episodes of
        Lord Krishna’s life with elegance and poise. This was followed by a quiz on the topic of ‘Events from
        Krishna’s Life and Mahabharata’. The audience participated enthusiastically. 
        <br></br>
        The assembly concluded with a speech on the application of the teachings from the Bhagwad Gita and
        Krishna’s struggles in the current times.
        <br></br>
        
        </Typography>
      </Box>
      {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “True strength lies not in Physical Force, but in the courage to peacefully resist the Injustice”

<br/>
        </Typography> */}

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly8C2024;